export const model = () => ([
  {
    model: 'carriage_no',
    title: '№ вагона',
    required: true,
    noControls: true,
    type: 'number'
  },
  {
    model: 'waybill_no',
    title: '№ накладной',
    type: 'input'
  },
  {
    model: 'current_station',
    title: 'Текущая станция',
    type: 'input'
  },
  {
    model: 'destination_station',
    title: 'Станция назначения',
    type: 'input'
  }
])

export const source = () => ({
  carriage_no: null,
  waybill_no: null,
  current_station: null,
  destination_station: null
})
